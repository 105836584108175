<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex flex-wrap">
          <CreatePredictionModal :competitions="competitions" @changeDate="getCompetition" @submit="createPrediction" />
          <div class="ml-1">
            <b-button @click="fetchResults" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-info" block>
              ดึงผลการแข่งขัน
            </b-button>
          </div>
        </div>
      </b-card-body>
      <b-table v-if="items" small striped hover responsive show-empty class="position-relative items-center" :fields="fields"
        :items="items.data">
        <template #cell(index)="data">
          {{ items.perPage * (items.currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(date)="row">
          {{ row.item.date }}
        </template>
        <template #cell(match1)="row">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <img :src="row.item.match1.homeTeam.crest" alt="Home" width="30" height="30">
              <div class="">{{ row.item.match1.homeTeam.tla }}</div>
            </div>
            <div class="px-1 text-success h4" v-if="row.item.match1.status === 'FINISHED'">{{
    row.item.match1.score.fullTime.home }} - {{ row.item.match1.score.fullTime.away }}</div>
            <div class="px-1 d-flex flex-column align-items-center" v-else>
              <div>VS</div>
              <div>{{ row.item.match1.startDateUTC | convertTime }}</div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <img :src="row.item.match1.awayTeam.crest" alt="Away" width="30" height="30">
              <div class="">{{ row.item.match1.awayTeam.tla }}</div>
            </div>
          </div>
        </template>
        <template #cell(match2)="row">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <img :src="row.item.match2.homeTeam.crest" alt="Home" width="30" height="30">
              <div class="">{{ row.item.match2.homeTeam.tla }}</div>
            </div>
            <div class="px-1 text-success h4" v-if="row.item.match2.status === 'FINISHED'">{{
    row.item.match2.score.fullTime.home }} - {{ row.item.match2.score.fullTime.away }}</div>
            <div class="px-1 d-flex flex-column align-items-center" v-else>
              <div>VS</div>
              <div>{{ row.item.match2.startDateUTC | convertTime }}</div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <img :src="row.item.match2.awayTeam.crest" alt="Away" width="30" height="30">
              <div class="">{{ row.item.match2.awayTeam.tla }}</div>
            </div>
          </div>
        </template>
        <template #cell(reward)="row">
          {{ row.item.reward.toLocaleString() }} บาท
        </template>
        <template #cell(close_at)="row">
          {{ row.item.close_at | convertTime }}
        </template>
        <template #cell(status)="row">
          <!-- <b-badge v-if="row.item.status == 0" pill variant="secondary">รอ</b-badge> -->
          <b-badge v-if="row.item.status == 0" pill>รอ</b-badge>
          <b-badge v-if="row.item.status == 1" pill variant="info">ดำเนินการ</b-badge>
          <b-badge v-if="row.item.status == 2" pill variant="success">สำเร็จ</b-badge>
        </template>
        <template  #cell(actions)="row">
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v" />
            </template>
            <!-- <b-dropdown-item v-if="row.item.status === 2" href="#">รายชื่อผู้โชคดี</b-dropdown-item> -->
            <b-dropdown-item v-if="row.item.status !== 0" @click="setStatus(row.item.id, 0)">สถานะรอ</b-dropdown-item>
            <b-dropdown-item v-if="row.item.status !== 1" @click="setStatus(row.item.id, 1)">สถานะดำเนินการ</b-dropdown-item>
            <b-dropdown-item v-if="row.item.status !== 2" @click="setStatus(row.item.id, 2)">สถานะสำเร็จ</b-dropdown-item>
            <b-dropdown-item v-if="row.item.status === 0" @click="deletePrediction(row.item.id)">ลบ</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body v-if="items" class="d-flex justify-content-between flex-wrap pt-0">
        <b-pagination v-model="items.page" :total-rows="items.total" :per-page="items.perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0" @change="getPredictions">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-card>

    <div>
      <LuckyWinner :items="luckyWinners"/>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import CreatePredictionModal from './index/createPredictionModal.vue'
import LuckyWinner from './index/luckyWinner.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone'

export default {
  components: {
    BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    CreatePredictionModal, LuckyWinner
  },
  directives: {
    Ripple
  },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'date', label: 'วัน/เดือน/ปี' },
      { key: 'match1', label: 'คู่ที่ 1' },
      { key: 'match2', label: 'คู่ที่ 2' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'close_at', label: 'เวลาปิด' },
      { key: 'status', label: 'สถานะ' },
      { key: 'actions', label: '#' },
    ],
    items: null,
    luckyWinners: null,
    competitions: [],
  }),
  filters: {
    convertDate(date) {
      return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  mounted() {
    this.getPredictions(1);
    this.getLuckyWinners(1);
    this.getCompetition(new Date);
  },
  methods: {
    async createPrediction(values) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.post('events/soccer-predictions', values);

        console.log(values);

        await this.$swal({
          icon: 'success',
          title: 'ทำรายการสำเร็จ',
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        await this.getPredictions(1);

      } catch (e) {
        console.log(e.response.data.message);
        await this.$swal({
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: e.response.data.message,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })


      } finally {
        this.isLoading = false;
      }


    },
    async deletePrediction(id) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.delete(`events/soccer-predictions/${id}`);

        // console.log(values);

        await this.$swal({
          icon: 'success',
          title: 'ทำรายการสำเร็จ',
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        await this.getPredictions(1);

      } catch (e) {
        // console.log(e.response.data.message);
        await this.$swal({
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: e.response.data.message,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })


      } finally {
        this.isLoading = false;
      }


    },
    async getCompetition(e) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get(`events/soccer-predictions/competitions?date=${new Date(e).toLocaleDateString()}`);

        this.competitions = data;
      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    },
    async getPredictions(page) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get(`events/soccer-predictions?page=${page}`);

        this.items = data;
      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    },
    async getLuckyWinners(page) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get(`events/soccer-predictions/lucky-winners?page=${page}`);

        this.luckyWinners = data;
      } catch (e) {

      } finally {
        this.isLoading = false;
      }
    },
    async fetchResults() {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get('events/soccer-predictions/fetch-results');

        await this.getPredictions(1);
        // console.log(data);
        // this.items = data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async setStatus(id, status_id) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.post(`events/soccer-predictions/set-status/${id}`, {
          status_id: status_id
        });

        await this.getPredictions(1);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
}
</script>